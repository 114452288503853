.App {
  display: flex;
  justify-content: space-between;
  background-color: #282c34;
  width: 100%;
  margin: 0 auto;
  max-width: 1760px;
  min-height: 100vh;
}
.codePart{
  display: block;
  width: 70%;
}

.visualPart{
  display: block;
  width: 29%;
  border-left: 2px solid #0e0d0d63;
}

.visualPart_wrapper{
  position: sticky;
  top: 20px;
}

header {
  background-color: #1b1b1b;
  color: #fff;
  height: 5vh;
  min-height: 20px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: min(16px, calc(10px + 2 * ((100vw - 320px) / 1024))) ;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navigation{
  display: flex;
  justify-content: start;
  
  
  background-color: #202020;
}

.navLink{
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;
  padding: 0 1% 0 1%;
  color: #fff;
  text-decoration: none;
  font-size: min(18px, calc(8px + 2 * ((100vw - 120px) / 824))) ;
  background-color: #202020;

  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}


.navLink:hover {
  background-color: #313131;
}