.main_answer_box{
    border: 1px solid #000;
    background-color: #1d1f2494;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto;
    margin-top: 2%;
    padding: 3%;
    box-shadow: #00000069 2px 1px 1px 1px ;

    display: flex;
    justify-content: space-between;
    align-items: center;
}